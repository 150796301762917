import React, { Component } from 'react';
import CreatableSelect  from 'react-select/lib/Creatable';

import PagePadded from '../components/pagepadded.js';
import api from "../api/index.js";

import './secretsanta.scss';

const createOption = (label: string, value : string) => ({
	label,
	value: value.toLowerCase(),
});


class Secretsanta extends Component {
	constructor(props) {
		super(props);
		
		this.loggedOut = this.loggedOut.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.dataLoaded = this.dataLoaded.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.friendsLoaded = this.friendsLoaded.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.sent =  this.sent.bind(this);
		
		const words = [	"Doom", "Friendship", "Happiness", "Awesome-ness", 
			"Mediocrity", "Obligation", "Rainbows", "Cheapness", "Mystery" ];
		var year = (new Date()).getFullYear();

		this.state = {
			loggedIn: false,
			isLoading: false,
			title: year + " Secret Santa of " + words[Math.floor(Math.random()*words.length)],
			name: '',
			description: '$20 maximum',
			options: [],
			value: [],
			friends: [],
			sent: false
		};
	}
	

	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	
	loggedOut(data) {
		this.setState({loggedIn: false});
	}
	
	loggedIn(data) {
		this.setState({loggedIn: true});
	}
	
	
	componentDidMount() {
		api.auth.addEventListener('loggedOut', this.loggedOut);
		api.auth.addEventListener('loggedIn', this.loggedIn);
		api.auth.addEventListener('userRetrieved', this.dataLoaded);
		api.friends.addEventListener('friendsRetrieved', this.friendsLoaded);
		this.setState({
			name: (api.auth.softUserProp('first_name') + ' ' + api.auth.softUserProp('last_name')).trim(),
			loggedIn: api.auth.isLoggedIn()
		});
		api.friends.retrieve();
	}
	
	componentWillUnmount() {
		api.auth.removeEventListener('loggedOut', this.loggedOut);
		api.auth.removeEventListener('loggedIn', this.loggedIn);
		api.auth.removeEventListener('userRetrieved', this.dataLoaded);
		api.friends.removeEventListener('friendsRetrieved', this.friendsLoaded);
	}

	
	
	
	dataLoaded(data) {
		api.auth.removeEventListener('userRetrieved', this.dataLoaded);
		this.setState({
			name: (api.auth.softUserProp('first_name') + ' ' + api.auth.softUserProp('last_name')).trim(),
			value: [...this.state.value, createOption('Me', api.auth.softUserProp('email_address'))]
		});
	}
	
	friendsLoaded(data) {
		let options = [createOption('Me', api.auth.softUserProp('email_address'))];
		for(let i=0; i < data.length; i++) {
			options.push(createOption(
				data[i].first_name + ' ' + data[i].last_name,
				data[i].email_address)
			);
		}
		this.setState({friends: data, options: options});
	}

	onSubmit(event) {
		event.preventDefault();
		api.secretsanta.addEventListener('sent', this.sent);
		let participants = [];
		for(let i=0; i < this.state.value.length; i++) {
			participants.push(this.state.value[i].value);
		}
		api.secretsanta.send(this.state.title, this.state.name, this.state.description, participants);
	}
	
	sent(data) {
		api.secretsanta.removeEventListener('sent', this.sent);
		this.setState({sent: true});
	}
	
	handleChange(newValue: any, actionMeta: any) {
		this.setState({ value: newValue });
	}
	
	handleCreate(inputValue: any) {
		this.setState({ isLoading: true });
		setTimeout(() => {
			const { options } = this.state;
			const newOption = createOption(inputValue, inputValue);
			this.setState({
				isLoading: false,
				options: [...options, newOption],
				value: newOption,
			});
		}, 1000);
	}
	
	handleKeyDown(event: SyntheticKeyboardEvent<HTMLElement>) {
		const { inputValue, value } = this.state;
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				this.setState({
				inputValue: '',
					value: [...value, createOption(inputValue)],
				});
				event.preventDefault();
				break;
			default:
				break;
		}
	}
	
	render() {
		if(this.state.sent) 
			return <PagePadded>
					<h1>Secret Santa</h1>
					<p>Your secret santa emails have been sent off into the internet.  
					It may take a bit for them to arrive, so don't panic if you don't see yours right away.</p>
					<p>But please do verify that everyone has received their email.  Make sure to check your spam folders!</p>
				</PagePadded>;
		
		return <PagePadded className="secretsanta">
				<h1>Secret Santa</h1>
				<form onSubmit={this.onSubmit}>
					<h4>Title</h4>
					<input type="text" name="title" value={this.state.title} onChange={this.onChange} />
					<h4>Your name</h4>
					<input type="text" name="name" value={this.state.name} onChange={this.onChange} />
					<h4>Description & rules</h4>
					<textarea name="description" value={this.state.description} onChange={this.onChange} />
					<h4>Participants</h4>
					<CreatableSelect className="participants"
						isClearable
						isMulti
						isDisabled={this.state.isLoading}
						isLoading={this.state.isLoading}
						onChange={this.handleChange}
						onKeyDown={this.handleKeyDown}
						placeholder="Participants"
						options={this.state.options}
						value={this.state.value}
					/>
					<p><input type="submit" value="Send the emails" /></p>
				</form>
			</PagePadded>;
	}
}

export default Secretsanta;
